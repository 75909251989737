/* @import url('https://fonts.cdnfonts.com/css/sweetie-potato'); */
@font-face {
    font-family: "Sweetie Potato";
    src: url("../Assets/Sweetie_Potato.eot?") format("eot"), url("../Assets/Sweetie_Potato.woff") format("woff"), url("../Assets/Sweetie_Potato.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


.main-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header-text {
    color: #0250F6;
    font-size: 110px;
    font-weight: 500;
    font-family: "Sweetie Potato";
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
}

.img-text {
    font-size: 40px;
    width: 500px;
    font-family: "Sweetie Potato";
}

@media screen and (min-width: 1024px) {
    .main-div {
        width: 98%;
        padding-left: 10px;
    }

    .header-text {
        font-size: 70px;
    }
}

/* @media screen and (max-width: 600px) {
    .center-div {
        display: flex;
        justify-content: center;
    }

    .header-text {
        font-size: 60px;
    }
} */
@media screen and (max-width: 1024px) {
    .main-div {
        display: flex;
        justify-content: center !important;
    }

    .center-div {
        display: flex;
        justify-content: center;
    }

    .img-div {
        width: 400px !important;
        height: unset !important;
    }

    .header-text {
        font-size: 70px;
        text-align: center;
    }

    .img-text {
        font-size: 40px;
        width: unset !important;
        padding-left: 1%;
        padding-right: 1%;
        text-align: center;
        width: 400px !important;
    }

    .arrow-div {
        margin-left: unset !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .arrow-img {
        width: 50%;
    }

    .box-div {
        width: 500px !important;
        /* height: 450px !important; */
    }

    .two-btns {
        margin-left: unset !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .add-img-btn {
        width: 80%;
    }

    .save-img-btn {
        width: 60%;
    }

    .label {
        display: flex;
        justify-content: center;
        margin-top: 5%;
    }
}

@media screen and (max-width: 820px) {
    .center-div {
        padding: 0 25px;
    }

    .img-div {
        width: 300px;
    }

    .img-text {
        width: unset !important;
    }
}

@media screen and (max-width: 767px) {
    .main-div {
        display: flex;
        justify-content: center !important;
        flex-wrap: wrap;
    }



    .center-div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .img-div {
        width: 85% !important;
        /* height: 600px !important; */
    }

    .header-text {
        font-size: 43px;
        text-align: center;
    }

    .img-text {
        font-size: 30px;
        width: unset !important;
        padding-left: 1%;
        padding-right: 1%;
        text-align: center;
    }

    .arrow-div {
        margin-left: unset !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .arrow-img {
        width: 50%;
        display: none;
    }

    .box-div {
        width: 85% !important;
        height: 420px !important;
    }

    .two-btns {
        margin-left: unset !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .add-img-btn {
        width: 80%;
    }

    .save-img-btn {
        width: 60%;
    }

    .label {
        display: flex;
        justify-content: center;
        margin-top: 5%;
    }
}